import { useField } from 'formik';
import React, { useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';

import { ErrorMessage } from '@/components/Forms';

type Props = { api: string; dev?: boolean };

const name = 'g-recaptcha-response';

const Captcha: React.FC<Props> = ({ api, dev = false }) => {
  const [, , { setValue, setTouched }] = useField(name);

  useEffect(() => {
    if (dev) {
      setValue('dev');
    }
    setTouched(false);
  }, []);

  const onChange = (token: string) => {
    setValue(token);
  };

  return (
    <div>
      <ReCAPTCHA sitekey={api} onChange={onChange} />
      <ErrorMessage name={name} />
    </div>
  );
};
export default Captcha;
