import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export type Tab = {
  title: string;
  path: string;
};

type Props = {
  tabs: Tab[];
};

const Tabs: React.FC<Props> = ({ tabs }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ul
      className='flex flex-col gap-16 items-center justify-start mt-10 w-full relative sm:flex-row'
      style={{ bottom: '-1px' }}
    >
      {tabs.map((tab) => (
        <li
          className={`${
            location.pathname.endsWith(tab.path) ? 'tab-active' : 'tab'
          } cursor-pointer leading-relaxed`}
          key={tab.title}
          onClick={() => navigate(tab.path)}
        >
          {tab.title}
        </li>
      ))}
    </ul>
  );
};
export default Tabs;
