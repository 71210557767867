import Off from '@/components/SVG/ToggleOff';
import On from '@/components/SVG/ToggleOn';
import React, { useState } from 'react';

const OperatorsToggle = ({ formName }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className='flex items-center gap-4 pb-12'>
      <button
        type='button'
        onClick={handleChange}
        className='flex items-center bg-white'
        aria-label='Toggle agreement'
      >
        {isChecked ? <On /> : <Off />}
      </button>
      <label className='flex flex-col'>
        <input
          type='checkbox'
          checked={isChecked}
          onChange={handleChange}
          name={`${formName}[agree_on_terms]`}
          className='hidden'
        />
        <span className='flex items-baseline gap-2 flex-wrap'>
          I agree to EvoLux’s
          <a
            href='/operator/terms'
            target='_blank'
            rel='noopener noreferrer'
            className='text-16px leading-none text-customBlue'
          >
            Terms of Service
          </a>
          <span className='text-16px text-customDarkGrey leading-24px'>
            and
          </span>
          <a
            href='/privacy'
            target='_blank'
            rel='noopener noreferrer'
            className='text-16px leading-none text-customBlue red-asterisk'
          >
            Privacy Policy
          </a>
        </span>
      </label>
    </div>
  );
};

export default OperatorsToggle;
