import React from 'react';
import { AiFillCheckCircle, AiOutlineCheckCircle } from 'react-icons/ai';

type CheckboxBtnProps = {
  onClick: () => void;
  isActive: boolean;
};

const ACTIVE = 'text-customBlue';
const INACTIVE = 'hover:bg-gray-300 text-customBlack';
const CheckboxBtn: React.FC<CheckboxBtnProps> = ({
  onClick,
  children,
  isActive,
}) => {
  return (
    <button
      className={`flex w-full cursor-pointer my-5  px-5 py-5 border-none text-16px font-customBlack rounded-md bg-bodyBg ${
        isActive ? ACTIVE : INACTIVE
      }`}
      onClick={onClick}
    >
      {isActive ? (
        <AiFillCheckCircle size={20} className='mx-5' />
      ) : (
        <AiOutlineCheckCircle size={20} className='mx-5' />
      )}{' '}
      {children}
    </button>
  );
};
export default CheckboxBtn;
