import { useFormikContext } from 'formik';
import React from 'react';

interface Props {
  text: string;
  className?: string;
}

const SubmitBtn: React.FC<Props> = ({ text = 'submit', className }) => {
  const { isSubmitting } = useFormikContext();
  return (
    <dl className={`${className} w-full p-0`}>
      <dd className='w-full '>
        <button type='submit' className='btn w-full' disabled={isSubmitting}>
          {isSubmitting ? 'Please wait...' : text}
        </button>
      </dd>
    </dl>
  );
};

export default SubmitBtn;
