import React, { useContext } from 'react';

import { EditProfileContext } from '@/contexts/EditProfileProvider';

export const VerifiedIdentifier: React.FC = ({ children }) => {
  const { user, toggleModal } = useContext(EditProfileContext);
  const isVerified = !!user.phone_verified_at;

  const handleVerifyNow = () => {
    if (user.agree_on_sms_receive) {
      window.location.href = '/users/phone_number_verification';
    } else {
      toggleModal('requestToSendSms');
    }
  };

  return isVerified ? (
    <>
      <div className='bg-lightGreen flex justify-center items-center w-16 h-16 rounded-full'>
        <img src='/icons/ok.svg' alt='check mark symbol' />
      </div>
      <span className='text-16px text-customBlack pl-6'>Verified</span>
    </>
  ) : (
    <>
      <div className='bg-pink  flex justify-center items-center w-16 h-16 rounded-full'>
        <img src='/icons/warning.svg' alt='warning symbol' />
      </div>
      <span className='text-16px text-customBlack pl-6 '>
        Pending Verification
      </span>
      <button
        type='button'
        onClick={handleVerifyNow}
        className='text-16px bg-transparent text-customBlue pl-6 underline decoration-solid cursor-pointer'
      >
        {children}
      </button>
    </>
  );
};
