import React, { useState } from 'react';
import { FiArrowRight } from 'react-icons/fi';

import {
  Notification,
  useGetNotifications,
  useMarkAsRead,
} from '@/API/notificationsApi';
import Modal from '@/components/Modal';

type Props = {
  notifications: Notification[];
};

const NotificationList: React.FC<Props> = (props) => {
  const { data } = useGetNotifications(props.notifications);
  const markAsRead = useMarkAsRead();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<Notification | null>(null);

  return (
    <>
      <Modal
        isOpen={open && !!selected}
        onRequestClose={() => {
          setOpen(false);
          setSelected(null);
        }}
        className='notification-modal'
      >
        {selected && (
          <div
            className='m-auto'
            dangerouslySetInnerHTML={{ __html: selected.content }}
          ></div>
        )}
      </Modal>
      <div className='w-full min-h-screen bg-white p-12 rounded-lg box-border'>
        {!data?.length ? (
          <p className='text-3xl'>No notifications</p>
        ) : (
          data.map((n) => (
            <button
              onClick={() => {
                setSelected(n);
                setOpen(true);
                if (!n.read) {
                  markAsRead.mutate(n.id);
                }
              }}
              key={n.id}
              className={`notification-item w-full border-none text-left cursor-pointer rounded-lg transition-all transform translate-x-0 md:hover:translate-x-5 px-8 py-6 my-5 bg-bodyBg shadow-md text-indigo-300 ${
                n.read ? 'opacity-50' : ''
              }`}
            >
              <h2 className='title text-customBlack text-18px mb-0 font-bold overflow-hidden overflow-ellipsis'>
                {n.subject}
                <p className='text-16px leading-relaxed text-customBlue font-normal font-serif'>
                  Learn More
                </p>
              </h2>
              <p className='time leading-relaxed text-gray-500 mb-2 text-16px'>
                {n.sentAt}
              </p>
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  if (!n.read) {
                    markAsRead.mutate(n.id);
                  }
                }}
                href={n.onClickUrl}
                className='take-action-button flex items-center btn w-max'
              >
                Take Action
                <FiArrowRight />
              </a>
            </button>
          ))
        )}
      </div>
    </>
  );
};
export default NotificationList;
