import { useContext, useMemo, useState } from 'react';

import { Route, useUpdateAccount } from '@/API/userApi';
import { EditProfileContext } from '@/contexts/EditProfileProvider';
import { capitalize, formatDate, strDate } from '@/Utils/helpers';
import { yup } from '@/Utils/validationConfig';

export const useEditProfileForm = () => {
  const {
    user,
    operator,
    user_route_preferences: routes,
    newsletter_frequencies: frequencies,
    toggleModal,
  } = useContext(EditProfileContext);

  const handleToggleModal = () => toggleModal('requestToSendSms');

  const [errors, setErrors] = useState<string[]>();

  const validationSchema = useMemo(
    () =>
      yup.object({
        date_of_birth: yup
          .string()
          .required()
          .test({
            message: 'the format must be mm/dd/yyyy',
            test: (value) => {
              if (!value) return false;
              return formatDate(strDate(value)) == value;
            },
          }),
        email: yup.string().email().required(),
        username: yup.string().min(2, 'too short').required(),
        first_name: yup.string().min(2, 'too short').required(),
        last_name: yup.string().min(2, 'too short').required(),
        phone: yup.string().min(6).required(),
        password: yup.string().min(8).optional(),
        password_confirmation: yup.string().when('password', {
          is: (val: string) => !!val,
          then: yup
            .string()
            .oneOf([yup.ref('password')], 'Passwords must match')
            .required(),
          otherwise: yup.string().optional(),
        }),
      }),
    [],
  );

  const initialValues = useMemo(() => {
    const {
      email,
      username,
      first_name,
      last_name,
      street,
      city,
      country,
      state,
      postal_code,
      time_zone,
      height,
      weight,
      phone,
      receive_shared_flight_notification,
      receive_emptyleg_flight_notification,
      newsletter_frequency,
      agree_on_sms_receive,
      date_of_birth,
    } = user;
    
    const agree_on_terms =
    user.role === 'operator'
      ? operator?.agree_on_terms ?? false // Ensure operator is not null
      : user.agree_on_terms ?? false; // Provide default value if user.agree_on_terms is null

    return {
      savedRoutesForm: {
        departure_airport: {},
        arrival_airport: {},
        returned: false,
        radius: 400,
      } as Route,
      // user Data
      receive_shared_flight_notification,
      receive_emptyleg_flight_notification,
      newsletter_frequency: frequencies[newsletter_frequency],
      saved_routes: routes,
      password: '',
      password_confirmation: '',
      email,
      username,
      first_name,
      last_name,
      street,
      city,
      country,
      state,
      postal_code,
      time_zone,
      height,
      weight,
      agree_on_terms,
      agree_on_sms_receive,
      phone,
      date_of_birth,
    };
  }, [user]);
  // initially touched to fix validation check
  const initialTouched = {
    savedRoutesForm: {
      arrival_airport: { airport_code: true },
      departure_airport: { airport_code: true },
      radius: true,
    },
  };

  const updateAccount = useUpdateAccount();

  const onSubmit = async (values, { setSubmitting }) => {
    const data = { user: values };
    delete data.user.savedRoutesForm;
    delete data.user.saved_routes;
    const isPhoneChanged = values.phone !== user.phone;
    if (!values.agree_on_sms_receive && isPhoneChanged) {
      handleToggleModal();
      setSubmitting(false);
      return;
    }
    try {
      const response = await updateAccount.mutateAsync(data);
      if (response.success) {
        return window.location.assign(response.signin_redirection);
      } else {
        setErrors(response.errors ?? response.error);
      }
    } catch (error) {
      if (error instanceof Error) {
        setErrors([error.message]);
      }
    }
  };

  return {
    initialValues,
    validationSchema,
    initialTouched,
    onSubmit,
    errors,
  };
};
