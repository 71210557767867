import React from 'react';

export type CardDataType = { number: string; date: string };
export type PreviousBillingFormProps = {
  action: string;
  cards: CardDataType[];
  flight_id: string;
  id: string; // booking id
};

const PreviousBillingForm: React.FC<PreviousBillingFormProps> = ({
  action,
  cards,
  flight_id,
  id,
}) => {
  return (
    <form action={action} method='POST'>
      <input type='hidden' name='id' value={id} />
      <input type='hidden' name='flight_id' value={flight_id} />
      {cards.map(({ number, date }) => {
        const name =
          cards.length > 1
            ? `use_previous_billing.${number}`
            : 'use_previous_billing';
        return (
          <span key={id} className='flex mt-2 relative'>
            <input
              type='radio'
              name='card'
              id={name}
              className='absolute top-1/2 left-8 transform translate-x-0 translate-y-center-y'
              defaultChecked={!!cards.length}
            />
            <label
              htmlFor={name}
              className={`w-full flex justify-between p-4 rounded-xl cursor-pointer border-1 border-solid`}
            >
              <p className='font-sans text-2xl font-bold ml-20'>
                {number.padStart(19, '**** ')}
              </p>
              <p className='font-sans text-2xl font-bold '>{date}</p>
            </label>
          </span>
        );
      })}
      <button id='submit' className='btn my-5'>
        <span id='button-text'>Confirm</span>
      </button>
    </form>
  );
};
export default PreviousBillingForm;
