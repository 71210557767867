import { Form, Formik } from 'formik';
import React, { useState } from 'react';

import ErrorsTooltip from '@/components/ErrorsTooltip';
import { Captcha, SubmitBtn } from '@/components/Forms';
import ContainerForInputs from '@/components/Forms/ContainerInput';
import Modal from '@/components/Modal';
import ProfileInfo from '@/components/Profile/ProfileInfo';
import ProfileSettings from '@/components/Profile/ProfileSettings';
import Steps from '@/components/Steps';
import { RegisterProps } from '@/pages/Register';
import { useRegisterForm } from '@/Utils/formHooks/useRegisterForm';

const RegisterForm: React.FC<RegisterProps> = ({
  newsletter_frequencies,
  google_maps_api_key,
  timeZones,
  recaptchaKey,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { currentStep, handleTabClick, errors, setErrors, ...registerForm } =
    useRegisterForm(() => {
      setIsOpen(true);
    });

  const tabs = [
    {
      title: 'Profile Settings',
      Content: (
        <ProfileInfo
          isEditForm={false}
          timeZones={timeZones}
          google_maps_api_key={google_maps_api_key}
        />
      ),
    },
    {
      title: 'Notification Settings',
      Content: (
        <>
          <ProfileSettings frequencies={newsletter_frequencies} isSignup />
          <Captcha api={recaptchaKey} />
        </>
      ),
    },
  ];

  return (
    <>
      <Modal isOpen={isOpen}>
        <div className='flex flex-col justify-center items-center'>
          <p className='w-full p-10 md:p-20 text-center text-3xl'>
            Your Account was created but you still need to confirm it, in order
            to continue please click on the confirmation link that was just sent
            to your email.
          </p>
          <a className='btn btn-red' href='/users/sign_in'>
            Go to the sign in page
          </a>
        </div>
      </Modal>
      <Formik {...registerForm}>
        {() => (
          <Form className='justify-center flex flex-wrap mx-auto register-form'>
            <ErrorsTooltip errors={errors} setErrors={setErrors} signIn />
            <Steps steps={tabs} active={currentStep} onClick={handleTabClick} />
            <ContainerForInputs>
              <SubmitBtn
                text={currentStep == 0 ? 'Next Step' : 'Create Account'}
              />
            </ContainerForInputs>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default RegisterForm;
