import React from 'react';
import '@/styles/notification.scss';
import { Notification } from '@/API/notificationsApi';
import ReactQueryProvider from '@/API/ReactQueryProvider';
import NotificationList from '@/components/NotificationList';

type Props = {
  notifications: Notification[];
};

const Notifications: React.FC<Props> = (props) => {
  return (
    <ReactQueryProvider>
      <NotificationList notifications={props.notifications} />
    </ReactQueryProvider>
  );
};
export default Notifications;
