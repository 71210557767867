import React from 'react';

const UserImg = ({ current_user }) => {
  return (
    <div className='pointer'>
      <span className='border-free w-14 h-14 border-solid border-2 rounded-full flex box-border'>
        <img
          className='w-full rounded-full object-cover'
          src={current_user.logo.url}
          alt='logo'
        />
      </span>
    </div>
  );
};
export default UserImg;
