import React, { createContext } from 'react';

import { EditProfileProps } from '@/pages/EditProfile';
import { useModalsState } from '@/Utils/useModalsState';

type ModalName = 'requestToSendSms';

export const EditProfileContext = createContext<
  EditProfileProps & {
    getModalState: (modalName: ModalName) => boolean;
    toggleModal: (modalName: ModalName) => void;
  }
>(null as any); // eslint-disable-line @typescript-eslint/no-explicit-any

export const EditProfileProvider: React.FC<EditProfileProps> = ({
  children,
  ...props
}) => {
  const { getModalState, toggleModal } = useModalsState({
    requestToSendSms: false,
  });

  return (
    <EditProfileContext.Provider
      value={{ ...props, getModalState, toggleModal }}
    >
      {children}
    </EditProfileContext.Provider>
  );
};
