import CardSkeleton from '@/components/Skeleton/CardSkeleton';
import React from 'react';

const mock = Array(6).fill(1);
const Skeleton = () => {
  return (
    <div className='results-grid grid gap-10 my-5 grid-cols-auto-fit-min-280'>
      {mock.map((_, index) => (
        <CardSkeleton key={index} />
      ))}
    </div>
  );
};
export default Skeleton;
