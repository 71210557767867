import React, { useMemo } from 'react';

import { User } from '@/API/models';
import { Input, PhoneInput, Select } from '@/components/Forms';
import AddressInput from '@/components/Forms/AddressInput';
import ContainerForInputs from '@/components/Forms/ContainerInput';
import RegisterToggles from '@/components/Forms/RegisterToggles';
import { VerifiedIdentifier } from '@/components/VerifiedIdentifier';

export type ProfileInfoProps = {
  timeZones: string[];
  google_maps_api_key: string;
} & (
  | {
      isEditForm?: false;
    }
  | {
      isEditForm: true;
      role: User['role'];
    }
);

const ProfileInfo: React.FC<ProfileInfoProps> = (props) => {
  const { timeZones, google_maps_api_key, isEditForm } = props;
  const timeZoneData = useMemo(() => {
    return timeZones.map((t) => ({ label: t[0], value: t[1] }));
  }, [timeZones.length]);

  return (
    <div className='pt-10 w-full'>
      <p className='font-sintony font-bold text-18px'>Personal Information</p>
      <ContainerForInputs>
        <Input name='first_name' isRequired />
        <Input name='last_name' isRequired />
      </ContainerForInputs>
      <ContainerForInputs>
        <Input
          name='date_of_birth'
          label='date of birth'
          placeholder='mm/dd/yyyy'
          isRequired
        />
      </ContainerForInputs>
      <p className='font-sintony font-bold text-18px pt-10 '>
        Account & Contact Information
      </p>
      <ContainerForInputs>
        <Input name='username' isRequired />
        <Input name='email' isRequired />
      </ContainerForInputs>
      <ContainerForInputs>
        <Input
          name='password'
          type='password'
          isRequired={!isEditForm}
          autoComplete='new-password'
        />
        <Input
          name='password_confirmation'
          label='confirm password'
          type='password'
          isRequired={!isEditForm}
        />
      </ContainerForInputs>
      <ContainerForInputs>
        <PhoneInput
          name='phone'
          label='phone number'
          className='w-full rounded-lg'
          isRequired
        />
        <span className='w-full flex-wrap flex items-center'>
          {isEditForm && props.role === 'user' && (
            <VerifiedIdentifier>Verify Now</VerifiedIdentifier>
          )}
        </span>
      </ContainerForInputs>
      <p className='font-sintony font-bold text-18px pt-10'>
        Flight-Related Information
      </p>

      <ContainerForInputs>
        <Input
          name='height'
          info='This information is required to ensure safety during flights and for the correct weight distribution on board'
        />
        <Input
          name='weight'
          info='This information is required to ensure safety during flights and for the correct weight distribution on board'
        />
      </ContainerForInputs>
      <AddressInput
        name='address'
        google_maps_api_key={google_maps_api_key}
        className='w-full p-0 pt-4'
      />
      <ContainerForInputs>
        <Select
          data={timeZoneData}
          name='time_zone'
          label='time zone'
          className='p-0 pt-4'
        />
      </ContainerForInputs>
      {(!isEditForm || (isEditForm && props.role !== 'evolux_partner')) && (
        <RegisterToggles
          role={isEditForm ? props.role : null}
          isEditForm={isEditForm}
        />
      )}
    </div>
  );
};
export default ProfileInfo;
