import React from 'react';
import { AiFillBank } from 'react-icons/ai';

import { StripeAccountType } from '@/API/stripeApi';
import Chip from '@/components/Chip';
import StripeButton from '@/components/Operator/Stripe/StripeButton';
import { countryCodeToName } from '@/Utils/helpers';

type Props = {
  account: StripeAccountType;
  link: string;
};

const Field: React.FC<{ label: string }> = ({ label, children }) => {
  return (
    <div className='py-5 flex max-w-5xl w-full items-center justify-between sm:justify-start gap-3'>
      <p className='font-bold w-1/3'>{label}</p>
      <span>{children ?? 'N/A'}</span>
    </div>
  );
};
const StripeAccountDetails: React.FC<Props> = ({ account, link }) => {
  const isComplete =
    account.payouts_enabled &&
    account.charges_enabled &&
    account.details_submitted &&
    account.requirements.eventually_due.length == 0;

  return (
    <div>
      {!isComplete && (
        <div className='font-bold p-5 rounded-lg bg-yellow-200 text-yellow-700 mb-10 '>
          Please provide your business banking or tax ID details in order to
          receive payments from EvoLux
        </div>
      )}
      {account.type === 'express' && (
        <StripeButton href={link}>Edit Account</StripeButton>
      )}
      <div className='pt-8 flex flex-wrap'>
        <div className='w-full sm:w-1/2 min-w-min'>
          <Field label='Status'>
            {isComplete ? (
              <Chip type='success' value='Complete' />
            ) : (
              <Chip type='error' value='Restricted' />
            )}
          </Field>
          {account.requirements.eventually_due.includes('company.tax_id') && (
            <Field label='Business Tax ID'>
              <Chip type='error' value='Missing' />
            </Field>
          )}
          <Field label='Company'>{account.business_profile.name}</Field>
          <Field label='Website URL'>{account.business_profile.url}</Field>
          <Field label='Email'>{account.email}</Field>
          <Field label='Country'>{countryCodeToName(account.country)}</Field>
          <Field label='Default Currency'>
            {account.default_currency.toUpperCase()}
          </Field>
        </div>
        <div className='flex flex-1 flex-col'>
          <h1 className='text-3xl mt-5'>Accounts</h1>
          {!account.external_accounts.data.length && (
            <h1 className='text-2xl flex my-5'>N/A</h1>
          )}
          {account.external_accounts.data.map((external) => (
            <div
              className='bg-gray-50 shadow-sm rounded-md p-5 mt-8'
              key={external.id}
            >
              <h1 className='text-2xl flex gap-2'>
                <AiFillBank size={20} />
                {external.bank_name}
              </h1>
              <span className='my-5 font-black block tracking-wider text-gray-700'>
                {external.last4.padStart(19, '**** ')}
              </span>
              <Field label='Account ID'>
                { account.id }
              </Field>
              <Field label='Account Type'>
                {external.object.replace('_', ' ')}
              </Field>
              <Field label='Account Holder'>
                {external.account_holder_name}
              </Field>
              <Field label='Routing Number'>{external.routing_number}</Field>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default StripeAccountDetails;
