import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useState } from 'react';

export type CardDetailsFormProps = {
  return_url: string;
};

const CardDetailsForm: React.FC<CardDetailsFormProps> = ({ return_url }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };
  return (
    <form onSubmit={handleSubmit} method='POST'>
      <div className='w-full box-border'>
        <PaymentElement id='payment-element' />
        <button
          disabled={isLoading || !stripe || !elements}
          id='submit'
          className='btn my-5'
        >
          <span id='button-text'>{isLoading ? 'Loading' : 'Confirm'}</span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id='payment-message'>{message}</div>}
      </div>
    </form>
  );
};

export default CardDetailsForm;
