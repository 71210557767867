import { Form } from 'formik';
import React, { useState } from 'react';
import { FiChevronsDown, FiChevronsUp, FiSearch } from 'react-icons/fi';

import {
  AirportInput,
  DateRangeInput,
  DebouncedInput,
  FormElement,
  Select,
  SubmitBtn,
  Toggle,
} from '@/components/Forms';
import ContainerForInputs from '@/components/Forms/ContainerInput';
import { AIRCRAFT_TYPES_DATA, AMENITIES, SAFETY } from '@/Utils/constants';
import useMediaQuery from '@/Utils/useMediaQuery';
import useOnScroll from '@/Utils/useOnScroll';

const FloatingSearchBtn = () => {
  const [visible, setvisible] = useState(true);
  useOnScroll(() => {
    if (isFormVisible()) {
      setvisible(true);
    } else {
      setvisible(false);
    }
  });
  const isFormVisible = () => {
    const searchForm = document.getElementById('search-form')!;
    const bounding = searchForm.getBoundingClientRect();
    const screenHeight =
      window.innerHeight || document.documentElement.clientHeight;

    return (
      bounding.top >= 0 ||
      (bounding.bottom >= 0 && bounding.bottom <= screenHeight)
    );
  };

  return (
    <button
      className={`fixed flex top-0  inset-x-0 mx-auto px-10 py-5 items-center
      rounded-lg border-none bg-white bg-opacity-95  text-2xl shadow-md

      ${visible ? 'hidden' : 'block animate-drop'}
      `}
      onClick={() => {
        document.getElementById('search-form')?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }}
      style={{ zIndex: 3 }}
    >
      Search
      <FiSearch className='ml-5' size={20} />
    </button>
  );
};

const Filters: React.FC<{ amenities: string[] }> = ({ amenities }) => {
  return (
    <>
      <ContainerForInputs>
        <Select
          className='h-40px'
          noLabel
          name='type'
          placeholder='by type'
          data={AIRCRAFT_TYPES_DATA}
          isMulti
        />
        <Select
          className=' h-40px'
          noLabel
          placeholder='by amenities'
          name='amenities'
          data={amenities.length > 0 ? amenities : AMENITIES}
          isMulti
        />
      </ContainerForInputs>
      <ContainerForInputs>
        <Select
          noLabel
          disabled
          placeholder='by safety rating (coming soon)'
          name='safetyRating'
          data={SAFETY}
          isMulti
        />
      </ContainerForInputs>
      <ContainerForInputs>
        <Toggle
          name='price'
          placeholder='Only show with prices'
          className='w-full'
        />
        <Toggle
          name='evolux_only'
          label='EvoLux only'
          placeholder='Only show created on EvoLux'
          className='w-full'
        />
      </ContainerForInputs>
    </>
  );
};
type Props = { amenities: string[] };

const SearchForm: React.FC<Props> = ({ amenities }) => {
  const [showFilters, setShowFilters] = useState(false);
  const isMobile = useMediaQuery('(max-width: 640px)');

  return (
    <>
      {isMobile && <FloatingSearchBtn />}
      <Form
        id='search-form'
        className='flex flex-wrap w-full p-10 pt-16 md:p-24 md:pt-14 box-border'
      >
        <ContainerForInputs>
          <AirportInput className='w-full rounded-lg' name='from' />
          <AirportInput className='w-full rounded-lg' name='to' />
        </ContainerForInputs>
        <ContainerForInputs>
          <DebouncedInput
            name='radius'
            label='radius: 0-400 miles'
            type='number'
            min='0'
            max='400'
            className='w-full rounded-lg'
          />
        </ContainerForInputs>

        <DateRangeInput name='dateRange' buttons={[0, 7, 30]} />
        {isMobile && (
          <button
            className='flex items-center justify-center btn btn-dimmed  rounded-lg mb-12'
            type='button'
            onClick={() => setShowFilters((v) => !v)}
          >
            <span>Filters</span>
            {showFilters ? (
              <FiChevronsUp size={20} />
            ) : (
              <FiChevronsDown size={20} />
            )}
          </button>
        )}
        <FormElement
          name='filters'
          className={`${
            !isMobile
              ? ''
              : showFilters
                ? 'animate-slide-down'
                : 'animate-slide-up'
          }  rounded-lg`}
        >
          <Filters amenities={amenities} />
        </FormElement>
        <SubmitBtn text='Search Flights' />
      </Form>
    </>
  );
};
export default SearchForm;
