import { useState } from 'react';

export const useModalsState = <T extends string = string>(
  defaultValue: Record<T, boolean> = {} as Record<T, boolean>,
) => {
  const [modalStates, setModalStates] = useState(defaultValue);

  const getModalState = (modalName: T) => !!modalStates[modalName];

  const toggleModal = (modalName: T) => {
    setModalStates((prev) => ({
      ...prev,
      [modalName]: !prev[modalName],
    }));
  };

  return { getModalState, toggleModal };
};
