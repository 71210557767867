import { Form, Formik } from 'formik';
import React from 'react';
import { Outlet } from 'react-router-dom';

import ErrorsTooltip from '@/components/ErrorsTooltip';
import { SubmitBtn } from '@/components/Forms';
import ContainerForInputs from '@/components/Forms/ContainerInput';
import { useEditProfileForm } from '@/Utils/formHooks/useEditProfileForm';

import RequestToSendSmsModal from './Modals/RequestToSendSmsModal';

const EditProfileForm: React.FC = () => {
  const { errors, ...formData } = useEditProfileForm();

  return (
    <>
      <Formik {...formData}>
        {({ errors: formErrors, submitCount }) => (
          <Form className='justify-center w-full flex flex-wrap mx-auto profile-form'>
            <ErrorsTooltip errors={errors} className='mt-12' />

            <div className='w-full pt-14'>
              <Outlet />
            </div>

            {submitCount > 0 && Object.keys(formErrors).length ? (
              <p className='text-customRed p-2 w-full font-bold'>
                Cannot submit, the form contains errors
              </p>
            ) : null}

            <ContainerForInputs>
              <SubmitBtn text='Save Account Details' />
            </ContainerForInputs>
          </Form>
        )}
      </Formik>

      <RequestToSendSmsModal />
    </>
  );
};
export default EditProfileForm;
