import React from 'react';
import { RouterProvider } from 'react-router-dom';

import { Operator, User } from '@/API/models';
import ReactQueryProvider from '@/API/ReactQueryProvider';
import { Route } from '@/API/userApi';
import { Frequencies } from '@/components/Profile/ProfileSettings';
import { EditProfileProvider } from '@/contexts/EditProfileProvider';
import { useEditProfileRouter } from '@/routers';

export type EditProfileProps = {
  timeZones: string[];
  user: User;
  operator: Operator;
  user_route_preferences: Route[];
  newsletter_frequencies: Frequencies; // {"daily": 0, "weekly":1, "monthly":2}
  google_maps_api_key: string;
};

const EditProfile: React.FC<EditProfileProps> = (props) => {
  const router = useEditProfileRouter(props);
  return (
    <ReactQueryProvider>
      <EditProfileProvider {...props}>
        <RouterProvider router={router} />
      </EditProfileProvider>
    </ReactQueryProvider>
  );
};
export default EditProfile;
