import React from 'react';

const ContainerForInputs = ({ children, className = '' }) => {
  return (
    <div
      className={`grid grid-cols-1 md:grid-cols-2 justify-center w-full gap-x-14  ${className} `}
    >
      {children}
    </div>
  );
};

export default ContainerForInputs;
