import { useField } from 'formik';
import React from 'react';

import { ErrorMessage } from '@/components/Forms';
import FormElement, { InputProps } from '@/components/Forms/FormElement';

type Props = InputProps;

const TextAreaInput: React.FC<Props> = (props) => {
  const [formik, , {}] = useField(props);
  return (
    <FormElement {...props}>
      <textarea
        {...formik}
        placeholder={props.placeholder}
        className='min-h-9rem'
      />
      <ErrorMessage name={props.name} />
    </FormElement>
  );
};
export default TextAreaInput;
