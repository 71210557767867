import { EventApi, EventContentArg, formatDate } from '@fullcalendar/react';
import React from 'react';
import { FaWrench } from 'react-icons/fa';
import { FiChevronRight } from 'react-icons/fi';

import { Airport } from '@/API/airportApi';
import { EventForm } from '@/types/AddEventForms';

const CalendarEvent: React.FC<EventContentArg> = ({ event, timeText }) => {
  const { arrival_airport, departure_airport, type, airport, ...extended } =
    event.extendedProps as EventForm;

  return (
    <div className='rounded-full flex flex-row flex-wrap'>
      {!event.allDay && (
        <>
          <Title type={type} title={event.title} />
          <NotAllDayEvent event={event} />
        </>
      )}
      {type &&
        (type === 'One-Way Special' ? (
          <>
            <Title type={type} title={event.title} />
            <EmptyLeg {...{ departure_airport, arrival_airport }} />
          </>
        ) : type == 'Transient' ? (
          <>
            <Title type={type} title={event.title} />
            <b>{airport.airport_code}</b>
            {extended.event_mode && (
              <div className='w-full'>{extended.event_title}</div>
            )}
          </>
        ) : type == 'Maintenance' ? null : (
          <div className='flex gap-8 flex-wrap'>
            <CustomCharter type={type} />
            <EmptyLeg {...{ departure_airport, arrival_airport }} />
          </div>
        ))}
    </div>
  );
};

const Title: React.FC<{ title: string; type: string }> = ({ title, type }) => {
  return (
    <span className='mr-8 text-xl flex'>
      {title}
      {type == 'Maintenance' && <FaWrench className='ml-4' size={20} />}
    </span>
  );
};

type ELProps = {
  departure_airport: Airport;
  arrival_airport: Airport;
};

const EmptyLeg: React.FC<ELProps> = ({
  arrival_airport,
  departure_airport,
}) => {
  return (
    <h2 className='title-font text-3xl font-medium flex'>
      <div>
        {departure_airport.airport_code}
        <p className='text-xl'>{departure_airport.location}</p>
      </div>
      <FiChevronRight className='self-center mx-2 flex-shrink-0' size={20} />
      <div>
        {arrival_airport.airport_code}
        <p className='text-xl'>{arrival_airport.location}</p>
      </div>
    </h2>
  );
};
const CustomCharter: React.FC<{ type: string }> = ({ type }) => {
  return (
    <div className='flex flex-row flex-wrap'>
      <span className='mr-8 text-xl flex'>
        {type.toLowerCase().includes('pending') ? 'Pending' : 'Confirmed'}
      </span>
      {type.includes('Skyshare') ? (
        <img className='w-40 max-w-xs' src={require('@images/SKYSHARE.svg')} />
      ) : type.includes('Skyprivate') ? (
        <img
          className='w-40 max-w-xs'
          src={require('@images/SKYPRIVATE.svg')}
        />
      ) : null}
    </div>
  );
};
export default CalendarEvent;
const NotAllDayEvent: React.FC<{ event: EventApi }> = ({ event }) => {
  return (
    <span className='text-xl flex flex-row flex-wrap'>
      {formatDate(event.start!, { hour: '2-digit', minute: '2-digit' })}
      <FiChevronRight className='self-center mx-2 flex-shrink-0' size={20} />
      {formatDate(event.end!, { hour: '2-digit', minute: '2-digit' })}
    </span>
  );
};
