import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import { EditProfileContext } from '@/contexts/EditProfileProvider';
import { PROFILE_ROUTES } from '@/routers';

import ProfilePicture from '../ProfilePicture';
import Tabs from '../Tabs';

const ProfileLayout: React.FC = () => {
  const { user } = useContext(EditProfileContext);

  const tabs = [
    {
      title: 'Profile Settings',
      path: PROFILE_ROUTES.PROFILE,
    },
    {
      title: 'Notification Settings',
      path: PROFILE_ROUTES.NOTIFICATION,
    },
  ];

  return (
    <>
      {/* NOTE: This div will become header during redesign */}
      <div>
        <ProfilePicture url={user.logo.url} tier={0} />
      </div>
      <div className='profile-tabs justify-center w-full flex flex-wrap mx-auto'>
        <Tabs tabs={tabs} />
      </div>

      <Outlet />
    </>
  );
};

export default ProfileLayout;
