import React, { useEffect, useRef, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { AiOutlineFullscreen } from 'react-icons/ai';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { ImAirplane } from 'react-icons/im';
import { ScrollPosition } from 'react-lazy-load-image-component';
import Modal from '@/components/Modal';

type LazyProps = {
  src: string;
  onClick?: () => void;
  className?: string;
  preloaded?: boolean;
};

const LazyLoader: React.FC<LazyProps> = ({
  src = '',
  onClick,
  className,
  preloaded = false,
}) => {
  const [isLoading, setLoading] = useState(!preloaded);

  const image = useRef(new Image()).current;
  const loadImage = () => {
    image.src = src;
    image.onload = () => {
      setLoading(false);
    };
    image.onerror = () => {
      setLoading(false);
    };
  };

  useEffect(() => {
    loadImage();
    return () => {
      setLoading(false);
      image.onload = null;
      image.onerror = null;
    };
  }, []);

  if (!src) {
    return (
      <div
        className={`${
          className ? 'h-80-vh' : 'h-96'
        } w-full bg-gray-200 text-center flex justify-center items-center flex-col`}
      >
        <ImAirplane size={40} />
        <p className='mt-10'>No Image</p>
      </div>
    );
  }
  return isLoading ? (
    <div
      className={`${
        className ? 'h-80-vh' : 'h-96'
      } w-full animate-pulse bg-gray-300 text-center flex justify-center items-center flex-col`}
    >
      <p className='mt-10'>Loading</p>
    </div>
  ) : (
    <img
      className={`bg-gray-100 rounded-sm ${
        !!className ? 'object-contain' : 'object-cover'
      } object-center w-full block ${className ? className : ' h-96'}`}
      src={src}
      onClick={onClick}
    />
  );
};

type Props = {
  images: string[];
};
type SliderProps = Props & {
  modal?: boolean;
  index: number;
  next: () => void;
  prev: () => void;
  disableBtn?: boolean;
};

const Slider: React.FC<SliderProps> = ({
  images,
  children,
  next,
  prev,
  index,
  disableBtn = false,
  modal = false,
}) => {
  return (
    <>
      <AliceCarousel
        disableSlideInfo
        disableButtonsControls
        disableDotsControls
        // autoHeight
        activeIndex={index}
        responsive={{ 0: { items: 1 } }}
        items={images.map((img, i) => (
          <LazyLoader
            preloaded={modal && i == 0}
            src={img}
            onClick={next}
            className={modal ? 'h-80-vh' : ''}
          />
        ))}
      />
      <div className='absolute top-2 left-2 rounded-md overflow-hidden flex justify-center'>
        {!disableBtn && (
          <>
            <button
              className='p-4 border-none opacity-30 hover:opacity-100 bg-white text-black hover:bg-bgBlack hover:text-white flex justify-center items-center'
              onClick={prev}
            >
              <FiChevronLeft size={modal ? 40 : 20} />
            </button>
            <button
              className='p-4 border-none opacity-30 hover:opacity-100 bg-white text-black hover:bg-bgBlack hover:text-white flex justify-center items-center'
              onClick={next}
            >
              <FiChevronRight size={modal ? 40 : 20} />
            </button>
          </>
        )}
        {children}
      </div>
    </>
  );
};

// const TrackedSlider = trackWindowScroll(Slider);

const ImageSlider: React.FC<Props> = ({ images = [] }) => {
  const [displayedImages, setDisplayedImages] = useState(images.slice(0, 1));
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index !== 0 && images.length !== displayedImages.length) {
      setDisplayedImages(images);
    }
  }, [index, images.length, displayedImages.length]);

  const next = React.useCallback(
    () => setIndex((s) => (s + 1) % images.length),
    [setIndex, images.length],
  );
  const prev = React.useCallback(
    () => setIndex((s) => (s - 1 + images.length) % images.length),
    [setIndex, images.length],
  );

  const [open, setOpen] = useState(false);
  useEffect(() => {
    setIndex(0);
  }, [open]);

  if (images.length <= 0)
    return (
      <div className='w-full bg-gray-200 h-96 text-center flex justify-center items-center flex-col'>
        <ImAirplane size={40} />
        <p className='mt-10'>No Images</p>
      </div>
    );

  return (
    <div className='flex-1 relative'>
      <Modal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        className='md:w-3/4 w-full p-10 bg-white rounded'
        noScrollLock
      >
        <div className='flex-1 relative h-80-vh'>
          <Slider modal {...{ index, prev, next, images: displayedImages }} />
        </div>
      </Modal>
      <Slider
        {...{
          index,
          prev,
          next,
          images: displayedImages,
          disableBtn: images.length <= 1,
        }}
      >
        <button
          className='p-4 border-none opacity-30 hover:opacity-100 bg-white text-black hover:bg-bgBlack hover:text-white flex justify-center items-center'
          onClick={() => setOpen(true)}
        >
          <AiOutlineFullscreen size={20} />
        </button>
      </Slider>
    </div>
  );
};
export default ImageSlider;
