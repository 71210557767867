import React from 'react';
import { createHashRouter, Navigate } from 'react-router-dom';

import EditProfileForm from '@/components/EditProfileForm';
import ProfileInfo from '@/components/Profile/ProfileInfo';
import ProfileLayout from '@/components/Profile/ProfileLayout';
import ProfileSettings from '@/components/Profile/ProfileSettings';
import { EditProfileProps } from '@/pages/EditProfile';

export const PROFILE_ROUTES = {
  ROOT: '/',
  PROFILE: 'profile',
  NOTIFICATION: 'notification',
  MEMBERSHIPS: 'memberships',
  BILLING: 'billing',
};

export const useEditProfileRouter = ({
  user: { role },
  timeZones,
  google_maps_api_key,
  newsletter_frequencies,
}: EditProfileProps) => {
  return createHashRouter([
    {
      element: <ProfileLayout />,
      children: [
        {
          element: <EditProfileForm />,
          children: [
            {
              path: PROFILE_ROUTES.ROOT,
              element: <Navigate to={PROFILE_ROUTES.PROFILE} replace />,
            },
            {
              path: PROFILE_ROUTES.PROFILE,
              element: (
                <ProfileInfo
                  isEditForm
                  role={role}
                  timeZones={timeZones}
                  google_maps_api_key={google_maps_api_key}
                />
              ),
            },
            {
              path: PROFILE_ROUTES.NOTIFICATION,
              element: <ProfileSettings frequencies={newsletter_frequencies} />,
            },
          ],
        },
        {
          path: PROFILE_ROUTES.MEMBERSHIPS,
          element: <p>Comming soon!</p>,
        },
        {
          path: PROFILE_ROUTES.BILLING,
          element: <p>Comming soon!</p>,
        },
      ],
    },
  ]);
};
