import 'react-phone-input-2/lib/style.css';

import React, { useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';

const PhoneInputCustom = ({
  value,
  inputName,
  label,
  labelClass,
}: {
  value: string;
  inputName: string;
  label?: string;
  labelClass?: string;
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [newValue, setNewValue] = useState(value);

  return (
    <dl className='phone-input-container'>
      {label && labelClass && (
        <dt>
          <label className={labelClass}>{label}</label>
        </dt>
      )}
      <dd>
        <ReactPhoneInput
          value={newValue}
          country={'us'}
          inputProps={{ name: inputName }}
          specialLabel='phone'
          inputClass={`w-full rounded-lg text-16px h-full border-0 `}
          containerStyle={{ borderRadius: '4px' }}
          containerClass={`w-full rounded-lg h-40px box-border ${isFocused ? 'border-customBlue border-solid border-1' : 'border-inputBorder border-solid border-1'} `}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={(value) => {
            setNewValue(value.startsWith('+') ? value : `+${value}`);
          }}
        />
      </dd>
    </dl>
  );
};
export default PhoneInputCustom;
