import 'react-day-picker/lib/style.css';

import { useField } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import DayPicker, {
  DateUtils,
  Modifier,
  Modifiers,
  RangeModifier,
} from 'react-day-picker';

import {
  ErrorMessage,
  FormElement,
  InputProps,
  TimeRangeInput,
} from '@/components/Forms';
import { addDays, formatDate } from '@/Utils/helpers';
import ContainerForInputs from '@/components/Forms/ContainerInput';

type Props = InputProps & { buttons?: number[]; withTime?: boolean };
type DateModifier = Date | null | undefined;
const initRange = {
  from: undefined,
  to: undefined,
};

const isSelectingFirstDay = (
  from: DateModifier,
  to: DateModifier,
  day: Date,
) => {
  const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
  const isRangeSelected = from && to;
  return !from || isBeforeFirstDay || isRangeSelected;
};

const DateRangeInput: React.FC<Props> = ({
  buttons = [],
  withTime = false,
  ...props
}) => {
  const [formik, {}, { setValue }] = useField(props);
  const [formatted, setFormatted] = useState({ from: '', to: '' });
  const [open, setOpen] = useState(false);
  const [range, setRange] = useState<RangeModifier>(initRange);
  const [enteredTo, setEnteredTo] = useState<Date>();

  useEffect(() => {
    // no initial value
    if (!formik.value) {
      setEnteredTo(undefined);
      setRange(initRange);
      return;
    }

    const { from, to } = formik.value;
    // prevent overriding range that is not yet saved
    if (open) {
      return;
    }

    // update to a new value
    setEnteredTo(undefined);
    setFormatted({ from: formatDate(from), to: formatDate(to) });
    setRange({ from, to });
  }, [open, formik.value]);

  const handleDayClick = (day: Date) => {
    // setRange(prev => DateUtils.addDayToRange(day, prev));
    const { from, to } = range;
    if (from && to && day >= from && day <= to) {
      handleResetClick();
      return;
    }
    if (isSelectingFirstDay(from, to, day)) {
      setRange({
        from: day,
        to: null,
      });
      setEnteredTo(undefined);
    } else {
      setRange((r) => ({
        ...r,
        to: day,
      }));
      setEnteredTo(day);
    }
  };

  const handleResetClick = () => {
    setEnteredTo(undefined);
    setRange(initRange);
    setDateRangeValue(initRange);
  };

  const handleDayMouseEnter = (day: Date) => {
    const { from, to } = range;
    if (!isSelectingFirstDay(from, to, day)) {
      setEnteredTo(day);
    }
  };

  const modifiers = useMemo(() => {
    const modifiers: Partial<Modifiers> = {
      start: range.from ?? undefined,
      end: enteredTo ?? range.to ?? undefined,
    };
    const disabledDays = range.from ? { before: range.from } : undefined;
    const selectedDays: Modifier[] = [
      range.from ?? undefined,
      { from: range.from, to: enteredTo ?? range.to },
    ];
    return { disabledDays, selectedDays, modifiers };
  }, [range, enteredTo]);

  const dayPicker = (
    <div className='relative w-full'>
      <button
        type='button'
        className='bg-white border border-solid border-gray-300 text-gray-500 text-left w-full flex items-center rounded-lg text-16px h-40px'
        onClick={() => setOpen(!open)}
      >
        {range.from && range.to
          ? `${range.from.toLocaleDateString()} ${
              formik.value.from_time ?? ''
            } - ${range.to.toLocaleDateString()} ${formik.value.to_time ?? ''}`
          : 'All Dates'}
      </button>

      <div
        className='absolute flex-row bg-white p-4 max-w-80-vw z-101 w-max  items-start justify-center shadow-md rounded-lg'
        style={{
          display: open ? 'flex' : 'none',
        }}
      >
        <div>
          <p className='flex justify-between flex-row-reverse text-14px text-center'>
            <button
              type='button'
              onClick={() => {
                setDateRangeValue(range);
                setOpen(false);
              }}
              disabled={!range.from || !range.to}
              style={{
                opacity: !range.from || !range.to ? 0.2 : 1,
                padding: 0,
                background: 'none',
                float: 'right',
                fontWeight: 700,
                color: '#0177B5',
              }}
            >
              Ok
            </button>
            <Messages {...range} handleResetClick={handleResetClick} />
          </p>
          <DayPicker
            className='Selectable'
            numberOfMonths={2}
            {...modifiers}
            onDayClick={handleDayClick}
            onDayMouseEnter={handleDayMouseEnter}
          />
          {withTime && <TimeRangeInput name={props.name} />}
        </div>
      </div>
      <div
        onClick={() => {
          if (range.from && range.to) {
            setDateRangeValue(range);
          }
          setOpen(false);
        }}
        className='fixed z-100 w-full-vw inset-0 bg-transparent'
        style={{
          display: open ? 'flex' : 'none',
          height: '100vw',
        }}
      ></div>
    </div>
  );
  const setDateRangeValue = (val: any) => {
    const newVal = { ...formik.value, ...val };
    setValue(newVal);
  };
  return (
    <FormElement {...props}>
      {buttons.length > 0 ? (
        <ContainerForInputs className='items-center gap-14'>
          {dayPicker}

          <div className='border-solid border-customBlue border-2 p-1 rounded-lg flex gap-3 w-max'>
            {buttons.map((btn) => (
              <PresetButtons
                days={btn}
                {...{ setDateRangeValue, formatted, handleResetClick }}
                key={btn}
              />
            ))}
          </div>
        </ContainerForInputs>
      ) : (
        dayPicker
      )}
      <ErrorMessage name={formik.name + '.from'} />
    </FormElement>
  );
};

const Messages = ({
  from,
  to,
  handleResetClick,
}: {
  from?: any;
  to?: any;
  handleResetClick: any;
}) => (
  <>
    {!from && !to && 'Please select the first day.'}
    {from && !to && 'Please select the last day.'}
    {from &&
      to &&
      `Selected from ${from!.toLocaleDateString()} to
      ${to!.toLocaleDateString()}`}{' '}
    {from && to && (
      <button
        type='button'
        style={{
          padding: 0,
          background: 'none',
          color: '#0177B5',
          fontWeight: 700,
          fontSize: '14px',
        }}
        onClick={handleResetClick}
      >
        Reset
      </button>
    )}
  </>
);

type PresetButtonsProps = {
  days: number;
  formatted: { from: string; to: string };
  handleResetClick: () => void;
  setDateRangeValue: (value: any, shouldValidate?: boolean | undefined) => void;
};
const PresetButtons: React.FC<PresetButtonsProps> = ({
  days,
  formatted,
  handleResetClick,
  setDateRangeValue,
}) => {
  const dateToSet = useMemo(
    () => ({ from: addDays(0), to: addDays(days) }),
    [days],
  );
  const clicked = useMemo(() => {
    return days == 0
      ? formatted.from == ''
      : formatDate(dateToSet.from) == formatted.from &&
          formatDate(dateToSet.to) == formatted.to;
  }, [formatted, dateToSet]);

  return (
    <button
      className={`${clicked ? 'btn-small' : 'btn-transparent'} rounded-lg h-14`}
      type='button'
      onClick={() => {
        if (days === 0) {
          return handleResetClick();
        }
        setDateRangeValue(dateToSet);
      }}
    >
      {days === 0 ? 'All Dates' : `${days} days`}
    </button>
  );
};

export default DateRangeInput;
