import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { FiInfo } from 'react-icons/fi';

import { Flight, User } from '@/API/models';
import ReactQueryProvider from '@/API/ReactQueryProvider';
import {
  AirportInput,
  DateInput,
  Input,
  PhoneInput,
  SubmitBtn,
  TextAreaInput,
  TimeInput,
  Toggle,
} from '@/components/Forms';
import ContainerForInputs from '@/components/Forms/ContainerInput';
import Modal from '@/components/Modal';
import Popover from '@/components/Popover';
import { useCheckAvailabilityForm } from '@/Utils/formHooks/useCheckAvailabilityForm';
import { useGetSeatPrice } from '@/Utils/formHooks/useGetSeatPrice';
import { strDate } from '@/Utils/helpers';

type Props = {
  flight: Flight & {
    action: string;
    bookingType: 'skyshare' | 'skylimo';
    phone?: string;
    enable_send_sms?: boolean;
    original_price?: number;
    pricing: {
      [key: number]: number;
    };
    price_config: {
      evolux_margin: number;
      passenger_margins: {
        [key: number]: number;
      };
    };
  };
};

const CheckAvailability: React.FC<Props> = ({ flight }) => {
  const [isOpen, setIsOpen] = useState(false);
  const checkAvailabilityForm = useCheckAvailabilityForm(flight, setIsOpen);

  return (
    <div>
      <ReactQueryProvider>
        <Modal isOpen={isOpen} className='check-availability-modal'>
          <div className='flex flex-col justify-center items-center'>
            <p className='w-full p-10'>
              Your Availability Inquiry for this One-Way Special has been sent
              to the aircraft operator. You will be notified as soon as they
              respond to your inquiry.
            </p>
            <a className='btn' href='/search_emptylegs'>
              Back to search page
            </a>
          </div>
        </Modal>
        <h2 className='text-4xl font-black mb-10 section-title  text-left mt-0'>
          {flight.bookingType == 'skylimo' ? 'SkyPrivate' : 'SkyShare'} -
          Customize your flight request
        </h2>
        <Formik {...checkAvailabilityForm}>
          {({ resetForm, setValues, values }) => {
            const seatPrice = useGetSeatPrice({
              price: values.asked_customer_price,
              price_config: flight.price_config,
              nbr_passengers: values.nbr_passengers,
              pricing: flight.pricing,
            });

            return (
              <Form className='flex flex-wrap w-full'>
                <ContainerForInputs>
                  <DateInput
                    className='w-full'
                    name='departure_date'
                    label='departure date'
                    isRequired
                    dateRange={
                      flight.departureDateLatest !== undefined
                        ? {
                            from: strDate(flight.departureDate)!,
                            to: strDate(flight.departureDateLatest)!,
                          }
                        : undefined
                    }
                  />
                  <TimeInput
                    className='w-full'
                    isRequired
                    name='departure_time'
                    label='departure time'
                    text={values.timezone}
                  />
                </ContainerForInputs>
                {/* <Input
                  name='timezone'
                  className='md:w-1/4 w-full md:pl-5 opacity-50'
                  disabled
                /> */}
                <AirportInput
                  className='w-full'
                  isRequired
                  name='departure_airport_code'
                  label='departure'
                />
                <AirportInput
                  className='w-full'
                  isRequired
                  name='arrival_airport_code'
                  label='arrival'
                />
                <p className='flex mt-3 mb-10 text-14px'>
                  Can I change the route?
                  <Popover
                    Btn={() => (
                      <FiInfo size={20} className='mx-4 text-gray-400' />
                    )}
                    content='Operators are typically looking for exact routing matches on these one-way specials due to tight schedules, however if the window of availability and pilot duty time allows for it, operators will likely accommodate slight alterations to these legs and just charge additional landing and flight time fees.'
                  />
                </p>
                <Input
                  className='w-full'
                  type='number'
                  name='nbr_passengers'
                  label={`anticipated number of passengers max( ${flight.capacity} )`}
                  placeholder='Anticipated Number of Passengers'
                />
                <div className='w-full flex justify-center items-center'>
                  <Input
                    className=''
                    name='asked_customer_price'
                    label={
                      !checkAvailabilityForm.initialValues.asked_customer_price
                        ? 'name your price ( all in total One-Way Special charter price )'
                        : 'posted price'
                    }
                    preText='$.'
                    placeholder='Enter a Price Suggestion'
                    disabled={
                      !!checkAvailabilityForm.initialValues.asked_customer_price
                    }
                    info={
                      flight.bookingType !== 'skyshare'
                        ? 'You are negotiating the price for the entire aircraft.'
                        : 'You are negotiating the price for the entire aircraft. You will be able to set seat price parameters and invite others to your crowdsoured flight ,once a final price is negotiated'
                    }
                  />
                  {flight.original_price &&
                    flight.price !== flight.original_price && (
                      <Popover
                        Btn={() => (
                          <FiInfo size={20} className='mx-4 text-gray-400' />
                        )}
                        content={`converted price from ${flight.original_price}`}
                      />
                    )}
                </div>
                {flight.bookingType == 'skyshare' && (
                  <>
                    <Input
                      className='w-full'
                      name='seat_price'
                      label='anticipated seat price'
                      preText='$.'
                      disabled
                      value={seatPrice}
                      info='The seat price based on the total price and the number of passengers.'
                      placeholder='Based on the Total Price and the Number of Passengers'
                    />
                    {!checkAvailabilityForm.initialValues
                      .asked_customer_price && (
                      <p className='w-full leading-relaxed pl-4 bg-yellow-200 p-4 mb-10 rounded-md'>
                        This is only an estimate of the final seat price
                      </p>
                    )}
                  </>
                )}
                <TextAreaInput
                  className='w-full'
                  label='comments or special requests'
                  name='check_availability_reason'
                />
                <p className='font-sintony font-bold text-18px'>
                  SMS notification
                </p>
                <Toggle
                  name='enable_send_sms'
                  noLabel
                  placeholder='Receive an SMS Notification'
                />
                {values.enable_send_sms && (
                  <>
                    <p className='w-full leading-relaxed text-14px mt-minus-35px pb-14 text-back'>
                      By providing your phone number, you agree to receive SMS
                      notifications from EvoLux related to your account and
                      service updates. Message frequency varies by your booking.
                      Message and data rates may apply. You can unsubscribe in
                      your profile at any time. Email{' '}
                      <a
                        href='mailto:info@evo-lux.com'
                        className='link'
                        target='blank'
                      >
                        info@evo-lux.com
                      </a>{' '}
                      for help.
                      <br />
                      <span>
                        By continuing, you agree to our{' '}
                        <a href='/privacy' className='link' target='blank'>
                          Privacy Policy
                        </a>{' '}
                        and{' '}
                        <a href='/terms' className='link' target='blank'>
                          Terms of Service.
                        </a>
                      </span>
                    </p>
                  </>
                )}
                <div className='grid grid-cols-warning-grid gap-4 items-center mb-10'>
                  <div className='w-16 h-16 rounded-full bg-pink relative '>
                    <img
                      src='/icons/warning.svg'
                      alt='warning icon'
                      className='absolute top-1/2 left-1/2 transform translate-x-center-x translate-y-center-y'
                    />
                  </div>
                  <p className='w-full leading-relaxed mb-0'>
                    One-Way Specials are time-sensitive in nature, and since
                    emails can easily get buried in your in-box, opt-in here to
                    receive an SMS when the operator responds to your
                    availability inquiry.
                  </p>
                </div>
                {values.enable_send_sms && (
                  <ContainerForInputs>
                    <PhoneInput name='phone' label='phone number' />
                  </ContainerForInputs>
                )}
                <div className='flex gap-16 w-full sm:flex-row flex-col'>
                  <button
                    type='reset'
                    className='sm:w-1/6 w-full btn-secondary'
                    onClick={React.useCallback(() => {
                      resetForm();
                      setValues(checkAvailabilityForm.initialValues);
                    }, [
                      resetForm,
                      setValues,
                      checkAvailabilityForm.initialValues,
                    ])}
                  >
                    Reset
                  </button>
                  <SubmitBtn
                    text='Check Availability'
                    className='w-full sm:w-5/6'
                  />
                </div>
                <div className='grid grid-cols-warning-grid gap-4 items-center mb-10'>
                  <div className='w-16 h-16 rounded-full bg-bodyBg relative '>
                    <img
                      src='/icons/info.svg'
                      alt='info icon'
                      className='absolute top-1/2 left-1/2 transform translate-x-center-x translate-y-center-y'
                    />
                  </div>
                  <p className='px-4 py-2 w-full mt-5 bg-bodyBg box-border rounded-lg'>
                    This will be sent to the operator who can choose to accept
                    or modify it.
                  </p>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ReactQueryProvider>
    </div>
  );
};
export default CheckAvailability;
