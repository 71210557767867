import React, { useMemo } from 'react';

import { Select, Toggle } from '@/components/Forms';
import SavedRoutes, { SavedRoutesProps } from '@/components/SavedRoutes';
import { capitalize } from '@/Utils/helpers';

export type Frequencies = {
  daily: number;
  weekly: number;
  monthly: number;
};

type Props = { frequencies: Frequencies } & SavedRoutesProps;

const ProfileSettings: React.FC<Props> = ({
  frequencies,
  ...savedRoutesProps
}) => {
  const options = useMemo(() => {
    return Object.entries(frequencies).map(([label, value]) => ({
      label: capitalize(label),
      value: value,
    }));
  }, [frequencies]);

  React.useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className='pt-10 w-full flex-wrap '>
      <p className='normal-case text-18px font-bold leading-24px'>
        Specify Your Preferred Routes
      </p>
      <p className='text-14px text-left p-0 leading-24px text-textGrey'>
        Tell us every route you’re interested in so we can send you special
        one-way charter opportunities and relevant SkyShare notifications.
      </p>
      <div className='w-full mb-16 flex flex-wrap'>
        <SavedRoutes {...savedRoutesProps} />
      </div>
      <p className='pt-10 normal-case text-18px font-bold leading-24px'>
        Notification Preferences
      </p>
      <div className='justify-center flex md:flex-nowrap flex-wrap w-full gap-x-14 pt-4'>
        <Toggle
          name='receive_emptyleg_flight_notification'
          label='One-Way-Specials Notifications'
          placeholder='Receive notifications for relevant One-Way Specials'
          className='w-full  md:w-1/2 p-0'
          labelClassName='font-bold'
        />
        <Select
          name='newsletter_frequency'
          label='One-Way-Specials notification frequency'
          data={options}
          className='w-full  md:w-1/2 p-0'
        />
      </div>
      <div className='justify-center flex md:flex-nowrap flex-wrap w-full gap-x-14 pt-4'>
        <Toggle
          name='receive_shared_flight_notification'
          label='SkyShare notifications'
          placeholder='Receive notifications for relevant SkyShare flights'
          className='w-full md:w-1/2 p-0'
          labelClassName='font-bold'
        />
        <p className='w-full md:w-1/2 p-0'></p>
      </div>
    </div>
  );
};
export default ProfileSettings;
