import { Form, Formik } from 'formik';
import React from 'react';

import { Aircraft } from '@/API/models';
import { SubmitBtn } from '@/components/Forms';
import EmptyLegFields from '@/components/Operator/Shared/EmptyLegFields';
import { useAddEmptyLegForm } from '@/Utils/formHooks/useAddEmptyLegForm';

type Props = {
  aircraft: Aircraft;
  afterSubmit: () => void;
};

const AddEmptyLegForm: React.FC<Props> = ({ aircraft, afterSubmit }) => {
  const form = useAddEmptyLegForm(aircraft, afterSubmit);
  return (
    <Formik {...form} validateOnChange>
      {() => (
        <Form className='add-ows-modal-form'>
          <h1 className='text-4xl pb-8 px-4'>
            Add One-way special - {aircraft?.tail_number}
          </h1>
          <EmptyLegFields />
          <SubmitBtn text='Add One-Way Special' />
        </Form>
      )}
    </Formik>
  );
};
export default AddEmptyLegForm;
